@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.slider {
  appearance: none;
  background: #d9d9d9;
  height: 7px;
  border-radius: 4px;
  width: 100%;
  outline: none;
  /* accent-color: #EA7B2D; */
}

.slider::-webkit-slider-thumb {
  appearance: none;
  /* background: #EA7B2D; */
  height: 18px;
  width: 18px;
  border-radius: 9px;
  cursor: pointer;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.remove-arrow {
    -moz-appearance: textfield;
}